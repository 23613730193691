.MiscButton {
  position: relative;
  width: 100%;
  height: 100%;
}

.MiscButton button {
  position: absolute;

  top: 50%;
  left:50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 20px;
  border: 2px solid #4d4b4b;
  color: #4d4b4b;
  background-color: white;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  /*transition-duration: 0.4s;*/
  cursor: pointer;
 
}

.MiscButton button:hover {
  color: white;
  animation: button-crazy2 1000ms;
  animation-iteration-count: infinite;
}
.MiscButton button:focus{
  outline: 0;
  box-shadow: none;
  /*background-color: rgb(92, 92, 92);*/
}

@keyframes button-crazy2 {
  0%    {background-color: rgb(252, 29, 29); color: rgb(111, 7, 185);}
  10%   {background-color: rgb(255, 101, 30); color: rgb(0, 183, 255);}
  20%   {background-color: yellow; color: rgb(8, 209, 8);}
  30%   {background-color: rgb(8, 209, 8); color: yellow;} 
  40%   {background-color: rgb(0, 183, 255); color: rgb(255, 101, 30);}
  50%   {background-color: rgb(111, 7, 185); color: rgb(252, 29, 29);}
  60%   {background-color: rgb(0, 183, 255); color: rgb(255, 101, 30);}
  70%   {background-color: rgb(8, 209, 8); color: yellow;}
  80%   {background-color: yellow; color: rgb(8, 209, 8);}
  90%   {background-color: rgb(255, 101, 30); color: rgb(0, 183, 255);}
  99%   {background-color: rgb(252, 29, 29); color: rgb(111, 7, 185);}
}

@keyframes button-crazy {
  0%    {background-color: rgb(252, 29, 29); }
  10%   {background-color: rgb(255, 101, 30);}
  20%   {background-color: yellow;}
  30%   {background-color: rgb(8, 209, 8);}
  40%   {background-color: rgb(0, 183, 255);}
  50%   {background-color: rgb(111, 7, 185); }
  60%   {background-color: rgb(0, 183, 255);}
  70%   {background-color: rgb(8, 209, 8);}
  80%   {background-color: yellow;}
  90%   {background-color: rgb(255, 101, 30);}
  99%   {background-color: rgb(252, 29, 29);}
}
