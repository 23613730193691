.Banner {
  box-sizing: border-box;
  background-color:rgb(255, 255, 255);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 20px;

  height: 15%;
  width: 82.5%;
  margin-left: 17.5%;


  display:flex;
  align-items: center;
  justify-content: center;
}

.Banner:hover {
  background-color: rgb(243, 243, 243);
}
