.MainDiv {
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  position: absolute;
  top:-21pxpx;
}

.App {
  color: #4d4b4b;
  font-family: Helvetica,  Arial, sans-serif;
  
  box-sizing: border-box;

  text-align: center;
  height: 400px;
  width: 600px;


  position: absolute;
  top: 50%;
  left:50%;
  
  -ms-transform: translate(-58.75%,-57.375%);
  transform: translate(-58.75%,-57.375%);
}