.SidebarItem {
  background-color: inherit;
  font-size: 15px;
}

.SidebarItem:hover {
  background-color: rgb(243, 243, 243);
}


.SidebarItem:focus{
  outline: 0;
  box-shadow: none;
  /*background-color: rgb(92, 92, 92);*/
}
