.ContactContent {
  position: relative;
  width: 100%;
  height: 100%;
}

.ContactContent h1 {
  text-align: center;
  font-size: 25px;
  /*background-color: rgb(103, 255, 116);*/
  box-sizing: border-box;
  padding: 5px;
  border-radius: 25px;
  position: absolute;

  animation-duration: 5s;
  animation-name: example;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
 
  height: 40px;
  width: 80px;
}

#T0 { animation-delay: 0ms;  }
#T1 { animation-delay: -200ms; }
#T2 { animation-delay: -360ms;  }
#T3 { animation-delay: -535ms;  }
#T4 { animation-delay: -700ms;  }
#T5 { animation-delay: -880ms;  }

:root{
  --topval: 0%;
  --botval: 80%;
  --lefval: 0px;
  --rigval: 82%;
}
@keyframes example {

  0%    { left: var(--lefval);  top: var(--topval); transform: rotate(0deg);  } 

  29%   { left: var(--rigval);  top: var(--topval); transform: rotate(0deg);  }
  30%   { left: var(--rigval);  top: var(--topval); transform: rotate(90deg);  }

  49%   { left: var(--rigval);  top: var(--botval); transform: rotate(90deg);  }
  50%   { left: var(--rigval);  top: var(--botval); transform: rotate(180deg);  }

  79%   { left: var(--lefval);  top: var(--botval); transform: rotate(180deg);  }
  80%   { left: var(--lefval);  top: var(--botval); transform: rotate(270deg);  }

  99%   { left: var(--lefval);  top: var(--topval); transform: rotate(270deg);  }
  100%  { left: var(--lefval);  top: var(--topval); transform: rotate(360deg);  }
}


.ContactContent img {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  margin: 0;

  position: absolute;
  top: 100px;
  left: 70px;
}

.ContactContent h2 {
  font-size: 20px;
  margin: 0;

  position: absolute;
  top: 100px;
  left: 170px;
}


.ContactContent p {
  font-size: 15px;
  width: 60%;
  box-sizing: border-box;

  text-align: left;
  position: absolute;
  top: 120px;
  left: 170px;
}
