.MainContent {
  background-color:rgb(255, 255, 255);
  box-sizing: border-box;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 20px;

  height: 81.25%;
  width: 82.5%;
  margin: 2.5% 0% 0% 2.5%;
  float: left;

  /*padding: 5px;
  text-align: justify;*/
}