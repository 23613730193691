.HomeContent{
  margin: 2%;
  position: relative;
  height: 92%;
  width: 96%;
}
.HomeContent h1 {
  text-align: center;
  font-size: 25px;
}
.HomeContent h1 em {
  color: rgb(252, 29, 29);
  animation: emphasize 4s;
  animation-iteration-count: infinite;
}
@keyframes emphasize {
  0%   {color: rgb(252, 29, 29);}
  10%   {color: rgb(255, 101, 30);}
  20%   {color: yellow;}
  30%   {color: rgb(8, 209, 8);}
  40%   {color: rgb(0, 183, 255);}
  50%   {color: rgb(111, 7, 185);}
  60%   {color: rgb(0, 183, 255);}
  70%   {color: rgb(8, 209, 8);}
  80%   {color: yellow;}
  90%   {color: rgb(255, 101, 30);}
  99%   {color: rgb(252, 29, 29);}
}

.HomeContent p {
  text-align: justify;
  padding: 1em;;
}

.HomeContent button {
  background-color: inherit;
  border: none;
  font: inherit;
  padding: 0;
  margin: 0;
  color: inherit;
  border-radius: 5px;
}

.HomeContent button:hover {
  background-color: rgb(103, 255, 116);
  color: deeppink;
}


.HomeContent button:focus{
  outline: 0;
  box-shadow: none;
  /*background-color: rgb(92, 92, 92);*/
}

.HomeContent footer
{
  width: 100%;
  position: absolute;
  bottom: 0%;
  font-size: 10px;;
  text-align: center;
}